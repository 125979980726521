import configBuilder from "components/ubold/ConfigBuilder";
import pageDefinitions from "../definitions";

import utils from "utils";

const definitions = {
  ...pageDefinitions,
  reloadAfterAdd: true,
  tabs: [
    {
      id: "monthly_report",
      name: "Monthly Reports",
    },
    {
      id: "t_report_file",
      name: "Report Files",
      alwaysEditable: true,
    },
  ],
  validators: {
    building: (value: number) => {
      return utils.validator.validateRequired(value);
    },
    room_variant: (value: number, state: any) => {
      if (state?.building_type === 1) return true;
      return utils.validator.validateRequired(value);
    },
    month: (value: number) => {
      return utils.validator.validateRequired(value);
    },
    year: (value: number) => {
      return utils.validator.validateRequired(value);
    },
  },
  validatorTabNotEmpty: {
    enabled: true,
    tabId: "t_report_file",
    tabName: "Report Files",
  },
};

export default configBuilder.buildAddEditPageConfig(
  definitions,
  ["file"],
  ["send_to"]
);
