import { withRouter } from "react-router-dom";

import utils from "../../../../utils";

function CTextField(props) {
  const {
    name,
    type = "text",
    state,
    defaultValue = null,
    maxLength = null,
    autoComplete = "off",
    readOnly = false,
    setState,
    setErrorMessage,
    validate,
    onChange,
    onKeyDown,
    min,
    isParkingAddon,
    onBlur,
    uppercase = false,
  } = props;

  const getWarningText = (value) => {
    const isValidNopol = utils.validator.validateNopol(
      value.toUpperCase()
    );

    if (!isValidNopol) {
      return (
        <div
          style={{ fontSize: 12, fontWeight: 500, color: "#FFB71B" }}
        >
          Peringatan: Nomor plat tidak valid!
        </div>
      );
    } else {
      return <div />;
    }
  };

  return (
    <div>
      <input
        id={`CTextField-${name ?? ""}`}
        type={type}
        name={name}
        value={state[name] ?? ""}
        defaultValue={defaultValue}
        placeholder={defaultValue}
        maxLength={maxLength ? maxLength : 1000}
        min={min}
        className="form-control"
        autoComplete={autoComplete}
        readOnly={readOnly}
        style={{
          backgroundColor: readOnly ? "#f8f8f8" : "#ffffff",
          color: !readOnly && "#000",
          textTransform: isParkingAddon && "uppercase",
        }}
        onKeyDown={onKeyDown}
        onWheel={(e) => e.target.blur()}
        onBlur={
          onBlur
            ? onBlur
            : (e) => {
                if (type === "number") {
                  const value = parseFloat(
                    e.target.value.replace(",", ".")
                  );
                  const num =
                    e.target.value === ""
                      ? 0
                      : value.toString().replace(/^0+/, "");
                  if (!setState) return;

                  setState((prevState) => ({
                    ...prevState,
                    [name]: num === "" ? 0 : Number(num),
                  }));
                }
              }
        }
        step={type === "number" ? "0.1" : false}
        onChange={
          onChange
            ? onChange
            : (e) => {
                const value =
                  uppercase && type === "text"
                    ? e.target.value.toUpperCase()
                    : e.target.value;
                setState((prevState) => ({
                  ...prevState,
                  [name]: value === "" ? null : value,
                }));

                if (
                  value !== "" &&
                  utils.commons.isFunction(validate)
                ) {
                  validate(value, setErrorMessage);
                } else {
                  if (
                    setErrorMessage &&
                    utils.commons.isFunction(setErrorMessage)
                  ) {
                    setErrorMessage(null);
                  }
                }
              }
        }
      />
      {type !== "number" &&
      maxLength &&
      state[name] &&
      maxLength === state[name].length ? (
        <span style={{ fontSize: "12px", color: "#bbbbbb" }}>
          Maximum characters {maxLength}
        </span>
      ) : (
        <></>
      )}
      {isParkingAddon && state[name] && getWarningText(state[name])}
    </div>
  );
}

export default withRouter(CTextField);
