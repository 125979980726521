import { withRouter } from "react-router-dom";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHTextField from "../../../../../../components/ubold/molecules/forms/CHTextField";
import CHSelect from "../../../../../../components/ubold/molecules/forms/CHSelect";
import CHAsyncSelect from "../../../../../../components/ubold/molecules/forms/CHAsyncSelect";
import CHRichTextField from "../../../../../../components/ubold/molecules/forms/CHRichTextField";
import CHCheckBox from "../../../../../../components/ubold/molecules/forms/CHCheckBox";
import CHTextView from "../../../../../../components/ubold/molecules/forms/CHTextView";

import Rent from "./Sections/Rent";

import utils from "../../../../../../utils";
import OtherInfo from "./Sections/OtherInfo";
import LegacyTenantInfo from "./Sections/LegacyTenantInfo";
import ServiceEligibility from "./Sections/ServiceEligibility";

function Order(props: any) {
  const { id, definitions, tab, state, setState } = props;

  const doneStatus = [4, 5, 6];

  return (
    <TabPane
      id={id}
      definitions={definitions}
      isAbleToDuplicate
      removedDuplicateFields={["number"]}
      tab={tab}
      state={state}
      setState={setState}
      readOnly={true}
      replaceValueWhenDuplicate={[
        {
          name: "transfer_from",
          value: "",
        },
        {
          name: "is_migration_data",
          value: false,
        },
      ]}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        <CHTextView name="number" title="Order ID" readOnly />
        <CHAsyncSelect
          name="tenant"
          title="Tenant"
          isRequired
          readOnly
          data="user/tenant?__type__=select_entries&limit=10"
          itemsExtractor={(row: {
            name: string;
            email: string;
            id: number;
            username: string;
            country_code: number;
            phone_number: number;
          }) => {
            return {
              label:
                row.name +
                " - " +
                row.email +
                " - " +
                row.country_code +
                row.phone_number,
              value: row.id,
            };
          }}
        />
        <CHSelect
          name="tenant_category"
          title="Tenant Category"
          readOnly
          isRequired
          data={{ optionField: "order_new_tenant_categories" }}
        />
        <CHAsyncSelect
          name="building"
          title="Building"
          isRequired
          readOnly
          data="building/building?__type__=select_entries&limit=10"
          itemsExtractor={(row: any) => {
            return {
              label:
                row.building_name +
                " - " +
                (row.building_type === 1
                  ? "Centralized"
                  : "Decentralized"),
              value: row.id,
            };
          }}
        />
        <CHAsyncSelect
          name="room_variant"
          title="Room Variant"
          dependsOn="building"
          isRequired
          readOnly
          data="building/room_variant?__type__=select_entries&limit=10"
          itemsExtractor={(row: any) => {
            return {
              label:
                row.name +
                " - " +
                utils.formatter.currency(row.price),
              value: row.id,
            };
          }}
        />
        <CHAsyncSelect
          name="room"
          title="Room"
          dependsOn="room_variant"
          readOnly
          data="building/room?__type__=select_entries&limit=10"
          itemsExtractor={(row: any) => {
            return {
              label:
                row.name +
                " (" +
                row["availability"] +
                ")" +
                " - " +
                utils.formatter.currency(row.price),
              value: row.id,
            };
          }}
        />
        <CHTextField
          name="deposit"
          title="Deposit Amount"
          type="number"
          readOnly
        />

        <CHSelect
          name="deposit_rules"
          title="Deposit Rules"
          data={{
            optionField: "order_new_deposit_rules",
          }}
          readOnly
          state={state}
        />

        {id && !doneStatus.includes(state["status"]) ? (
          <CHRichTextField
            name="deposit_notes"
            title="Deposit Notes"
            readOnly
          />
        ) : (
          <CHTextView
            name="deposit_notes"
            title="Deposit Notes"
            isSafeContent
            readOnly
          />
        )}
        <CHTextView
          name="price"
          title="Price"
          readOnly
          renderer={(value: number) => {
            return utils.formatter.currency(value);
          }}
        />

        <CHCheckBox
          name="is_migration_data"
          title="Mark this order as migration order"
          readOnly
        />

        <CHCheckBox
          name="exclude_deposit"
          title="Exclude Deposit"
          readOnly
        />
      </TabPaneSection>
      <TabPaneSection accordionId="formAccordion">
        <LegacyTenantInfo />
        <Rent />
        <ServiceEligibility />
        <OtherInfo />
      </TabPaneSection>
    </TabPane>
  );
}

export default withRouter(Order);
